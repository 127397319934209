<template>
  <div>
    <b-row>
      <b-col class="col-lg-12">
        <h1 class="d-inline mr-3">Chat Filters</h1>
        <chat-filter-recent-activity-log
          class="align-top pt-3"
          :bot-id="botId"
          :chat-filters="items"
          @selected="selectedChatFilterFromActivityLog"
        />
      </b-col>
      <b-col class="col-lg-3 mt-sm-3">
        <bot-selector @botChanged="refreshData" />
      </b-col>
    </b-row>

    <b-row>
      <b-col class="my-3">
        <b-button size="sm" variant="primary" @click="showModal">
          <b-icon-plus style="font-size: 1.2em" />
          Add
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="col-lg-8 mt-3">
        <div ref="table-container">
          <div v-if="isBusy" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-table
            bordered
            small
            striped
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isBusy"
            class="ip-table"
          >
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(chatFilterId)="data">
              <chat-filter-activity-log
                class="btn-inline-text"
                :bot-id="botId"
                :chat-filter-id="data.value"
              />
              <b-icon-pencil-square
                class="btn-inline-text ml-1"
                @click="showModalForEdit(data.item)"
                title="Edit"
              ></b-icon-pencil-square>
              <b-icon-trash
                class="btn-inline-text ml-1"
                @click="deleteItem(data.item)"
                title="Delete"
              ></b-icon-trash>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>

    <b-modal
      centered
      ref="form-modal"
      :title="isEdit ? 'Edit chat filter' : 'Add chat filter'"
    >
      <b-form ref="main-form">
        <div class="form-group row">
          <div class="col-sm-3">
            <label>Filter Name</label>
          </div>
          <div class="col-sm-9">
            <b-input
              class="form-control-sm"
              v-model.trim="formItem.filterName"
              required
              @change="$sanitize('formItem.filterName')"
            ></b-input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label>Pattern</label>
          </div>
          <div class="col-sm-9">
            <b-textarea
              class="form-control-sm"
              v-model.trim="formItem.pattern"
              required
            ></b-textarea>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label>Action</label>
          </div>
          <div class="col-sm-9">
            <b-form-select
              v-model="formItem.action"
              :options="[
                { value: 1, text: 'Remove' },
                { value: 2, text: 'Replace' },
              ]"
              size="sm"
            />
          </div>
        </div>
        <div class="form-group row" v-show="formItem.action === 2">
          <div class="col-sm-3">
            <label>Replace</label>
          </div>
          <div class="col-sm-9">
            <b-input
              class="form-control-sm"
              v-model.trim="formItem.replace"
            ></b-input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label>Priority</label>
          </div>
          <div class="col-sm-9">
            <b-input
              class="form-control-sm"
              v-model.trim="formItem.priority"
              required
            ></b-input>
          </div>
        </div>
        <div>
          <regex-tester
            :pattern="patterns"
            :action="formItem.action"
            :replace="formItem.replace"
          />
        </div>
      </b-form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="hideModal">Cancel</b-button>
        <b-button variant="primary" @click="addOrEditItem"
          >{{ isEdit ? 'Edit' : 'Add' }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import RegexTester from '@/components/RegexTester';
import BotSelector from '@/components/UI/BotSelector';
import ChatFilterRecentActivityLog from '@/components/Bots/ChatFilterRecentActivityLog.vue';
import ChatFilterActivityLog from '@/components/Bots/ChatFilterActivityLog.vue';

export default {
  /*global _*/
  mixins: [httpclient],
  components: {
    RegexTester,
    BotSelector,
    ChatFilterRecentActivityLog,
    ChatFilterActivityLog,
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.refreshData(botId);
  },
  data() {
    return {
      path: 'chatfilters',
      botId: 1,
      bots: [],

      isEdit: false,
      editId: -1,
      formItem: {
        chatFilterId: -1,
        filterName: '',
        pattern: '',
        action: 1,
        replace: '',
        priority: 1,
      },
      formItemDefault: {
        chatFilterId: -1,
        filterName: '',
        pattern: '',
        action: 1,
        replace: '',
        priority: 1,
      },

      fields: [
        {
          key: 'filterName',
          label: 'Filter Name',
          sortable: true,
        },
        {
          key: 'chatFilterId',
          label: '',
          sortable: false,
        },
      ],
      items: [],
      perPage: 10000,
      currentPage: 1,
      isBusy: false,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    patterns() {
      return this.formItem.pattern.split(/\r?\n/);
    },
  },
  methods: {
    showModal() {
      this.isEdit = false;
      this.editId = -1;
      this.formItem = Object.assign({}, this.formItemDefault);
      this.formItem.options = [''];
      this.$refs['form-modal'].show();
    },
    showModalForEdit(data) {
      this.isEdit = true;
      this.editId = data.chatFilterId;
      this.formItem = _.cloneDeep(_.assign({}, this.formItemDefault, data));
      this.formItem.pattern = JSON.parse(this.formItem.pattern).join('\n');
      this.$refs['form-modal'].show();
    },
    hideModal() {
      this.$refs['form-modal'].hide();
    },
    refreshData(botId) {
      if (botId) this.botId = botId;

      const vm = this;
      this.httpclient
        .get('api/chatfilters', { params: { botId: vm.botId } })
        .then(function (resp) {
          if (resp.data) {
            vm.items = resp.data;
            vm.$refs['form-modal'].hide();
          }
        });
    },
    deleteItem(data) {
      const vm = this;
      let confirmMessage = 'Remove the chat filter?';
      let id = data.chatFilterId;

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.httpclient.delete('api/chatfilters/' + id).then(function (resp) {
              if (resp.data) {
                let index = _.findIndex(vm.items, { chatFilterId: id });
                vm.items.splice(index, 1);
                vm.$refs['form-modal'].hide();
                vm.$bvModal.msgBoxOk(
                  'Chat filter had been removed successfully',
                  {
                    okVariant: 'success',
                    centered: true,
                  }
                );
              }
            });
          }
        });
    },
    addOrEditItem() {
      const vm = this;
      const mainForm = vm.$refs['main-form'];

      if (!mainForm.reportValidity()) {
        return;
      }

      vm.$refs['form-modal'].hide();

      let confirmMessage = vm.isEdit
        ? 'Update the chat filter?'
        : 'Add the chat filter?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            if (vm.isEdit) {
              vm.editItem();
            } else {
              vm.addItem();
            }
          } else {
            vm.$refs['form-modal'].show();
          }
        });
    },
    editItem() {
      const vm = this;
      let item = _.cloneDeep(vm.formItem);
      item.pattern = JSON.stringify(vm.patterns);
      item.botId = vm.botId;
      vm.httpclient.put('api/chatfilters', item).then(function (resp) {
        if (resp.data) {
          let index = _.findIndex(vm.items, { chatFilterId: vm.editId });
          vm.items.splice(index, 1, item);
          vm.$refs['form-modal'].hide();
          vm.$bvModal.msgBoxOk('Chat filter had been updated successfully', {
            okVariant: 'success',
            centered: true,
          });
        }
      });
    },
    addItem() {
      const vm = this;
      let item = _.cloneDeep(vm.formItem);
      item.pattern = JSON.stringify(vm.patterns);
      item.botId = vm.botId;
      vm.httpclient.post('api/chatfilters', item).then(function (resp) {
        let respData = resp.data;
        if (respData.isSuccess) {
          vm.items.push(respData.data);
          vm.$refs['form-modal'].hide();
          vm.$bvModal.msgBoxOk('Chat filter had been added successfully', {
            okVariant: 'success',
            centered: true,
          });
        }
      });
    },
    selectedChatFilterFromActivityLog(id) {
      let chatFilter = _.find(this.items, { chatFilterId: id });
      this.showModalForEdit(chatFilter);
    },
  },
};
</script>

<style scoped></style>
