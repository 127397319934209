<template>
  <div class="d-inline-block">
    <!--    <b-button-->
    <!--      class="list-button"-->
    <!--      variant="outline-primary"-->
    <!--      size="sm"-->
    <!--      @click="getActivityLogs"-->
    <!--      title="show history"-->
    <!--    >-->
    <!--      <b-icon icon="clock-history" class="list-button" />-->
    <!--    </b-button>-->
    <b-icon
      icon="clock-history"
      class="clickable"
      variant="primary"
      style="font-size: 1.2em"
      @click="getActivityLogs"
    />
    <b-modal v-model="modalList" size="lg" centered scrollable hide-footer>
      <b-table-lite
        v-if="list.length"
        :items="items"
        :fields="fields"
        bordered
        striped
      >
        <template #cell(actionDatetime)="row">
          {{ row.value | datetimelong }}
        </template>
        <template #cell(showDetails)="row">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </template>
        <template #row-details="row">
          <b-row v-for="change in row.item.comparedObj" :key="change.key">
            <b-col v-if="change.key === 'Pattern'">
              <small class="mr-2 d-block">{{ change.key }}</small>
              <small class="mr-2 text-warning">{{ change.old }}</small>
              <b-icon-arrow-right class="mr-2" />
              <small class="mr-2 text-success">{{ change.new }}</small>
            </b-col>
            <b-col v-else>
              <small class="mr-2 d-block">{{ change.key }}</small>
              <small class="mr-2 text-warning">[{{ change.old }}]</small>
              <b-icon-arrow-right class="mr-2" />
              <small class="mr-2 text-success">[{{ change.new }}]</small>
            </b-col>
          </b-row>
        </template>
      </b-table-lite>
      <span v-else>There is no recent history</span>
    </b-modal>
  </div>
</template>

<script>
import HttpHandler from '@/mixins/HttpHandler';
import _ from 'lodash';

export default {
  name: 'ChatFilterActivityLog',
  props: {
    botId: Number,
    chatFilterId: Number,
  },
  data() {
    return {
      list: [],
      modalList: false,
      fields: [
        {
          key: 'actionDatetime',
          label: 'Datetime',
        },
        {
          key: 'userName',
          label: 'Username',
        },
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'showDetails',
          label: '',
        },
      ],
    };
  },
  computed: {
    items() {
      let list = _.map(this.list, (log) => {
        let logObj = _.pick(log, [
          'actionDatetime',
          'userName',
          'action',
          'data',
        ]);
        const dataObj = JSON.parse(logObj.data);
        let obj = this.getEmptyObject({ ...(dataObj.Old || dataObj.New) });
        let oldObj = { ...obj, ...dataObj.Old };
        let newObj = { ...obj, ...dataObj.New };

        const propsToBeOmitted = [
          'BotId',
          'ChatFilterId',
          'CreatedAt',
          'CreatedBy',
          'UpdatedAt',
          'UpdatedBy',
        ];

        let compareObj = _.omit(oldObj, propsToBeOmitted);
        let comparedObj = [];
        _.each(compareObj, (value, key) => {
          let oldValue = value;
          let newValue = newObj[key];

          if (oldValue !== newValue) {
            if (key === 'Action') {
              oldValue = this.getActionDescription(oldValue);
              newValue = this.getActionDescription(newValue);
            }
            comparedObj.push({ key: key, old: oldValue, new: newValue });
          }
        });
        logObj.comparedObj = comparedObj;

        return logObj;
      });
      return list;
    },
  },
  mixins: [HttpHandler],
  methods: {
    async getActivityLogs() {
      const url = encodeURI(
        `/api/bots/${this.botId}/chat-filters/${this.chatFilterId}/activity-logs`
      );
      let resp = await this.httpclient.get(url);
      this.list = resp.data;
      this.modalList = true;
    },
    getEmptyObject(obj) {
      obj = { ...obj };
      Object.keys(obj).forEach((i) => (obj[i] = null));
      return obj;
    },
    getActionDescription(action) {
      switch (action) {
        case 1:
          return 'Remove';
        case 2:
          return 'Replace';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.list-button {
  padding: 1px 3px;
}

.list-button:hover {
  color: #fff;
}

/*.list-button:hover {*/
/*  border: 1px solid #00b6f0;*/
/*  border-radius: 2px;*/
/*  padding: 2px;*/
/*}*/
</style>
