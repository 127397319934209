<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-textarea v-model="sampleText" placeholder="Sample Text ..." />
      </b-col>
      <b-col cols="6">
        <b-textarea v-model="filteredText" readonly />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  /*global _*/
  props: ['pattern', 'action', 'replace'],
  data() {
    return {
      sampleText: '',
    };
  },

  computed: {
    filteredText() {
      const vm = this;
      let result = this.sampleText;
      _.forEach(this.pattern, function (pattern) {
        try {
          let regex = new RegExp(pattern, 'g');
          result = result.replace(regex, vm.textToReplace);
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.log(ex);
          //ignore
        }
      });

      return result;
    },
    textToReplace() {
      return this.action === 2 ? this.replace : '';
    },
  },
  methods: {},
};
</script>
